<template><div><h1 id="manually-flashing-roms" tabindex="-1"><a class="header-anchor" href="#manually-flashing-roms"><span>Manually Flashing ROMs</span></a></h1>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>Flashing your own firmware has the potential to brick your device. Do not do this unless you are sure you know what you are doing/have a way to recover from a bad flash. Some level of knowledge with using the Linux command line is required.</p>
</div>
<ol>
<li>Have your firmware rom. We will assume you already have the file you want to flash for this guide.</li>
<li>Download flashrom, then give it execute permission.
<ul>
<li><code v-pre>cd; curl -LO https://tree123.org/chrultrabook/utils/flashrom-libpci38; chmod +x flashrom-libpci38</code></li>
</ul>
</li>
<li>Flash your custom ROM
<ul>
<li>Backup your current rom, just in case things go wrong: <code v-pre>./flashrom-libpci38 -p internal -r current.rom</code></li>
<li>Note: Intel Chromebooks should be flashed with <code v-pre>--ifd -i bios</code> ex: <code v-pre>sudo ./flashrom -p internal --ifd -i bios -w coreboot.rom</code></li>
<li>Flash your custom firmware: <code v-pre>sudo ./flashrom-libpci38 -p internal -w firmware.rom</code></li>
</ul>
</li>
<li>Reboot
<ul>
<li>Assuming it said <code v-pre>success</code> on all checks, reboot.</li>
</ul>
</li>
</ol>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>Do not reboot if any of the checks failed.</p>
</div>
</div></template>


